.RevokeTokenForm {
  max-width: 420px;

  &__field {
    margin-bottom: 1rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  &__actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
