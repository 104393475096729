@import '~/src/scss/mixins';
@import '~/src/scss/variables';

@mixin input-base {
  @include inter;
  background: white;
  border: 1px solid $border;
  border-radius: 12px;
  font-size: 1rem;
  outline: none;
  padding: 16px;
  transition: all 0.1s ease-in-out;

  @include placeholder {
    color: $oc-gray-6;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.Input--fluid {
    width: 100%;
  }

  &.Input--rounded {
    border-radius: 2rem;
  }

  &.Input--iconLeading {
    padding-left: 2.5rem !important;
  }

  &.Input--iconTrailing {
    padding-right: 2.5rem !important;
  }

  &--textarea {
    max-width: 100%;
    min-height: 8rem;
    padding: 1rem;
    resize: none;
  }

  &.Input--size--sm {
    font-size: 0.75rem;
    padding: 0.75rem;
  }

  &.Input--size--lg {
    font-size: 1.25rem;
    padding: 1.25rem;
  }
}

.Input__container {
  display: inline-block;
  position: relative;

  &.Input__container--fluid {
    width: 100%;
  }

  .material-icons {
    display: block;
  }

  .iconLeading {
    font-size: 1.25rem;
    left: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
  }

  .iconTrailing {
    font-size: 1.25rem;
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.Input {
  @include input-base;

  &:hover {
    border: 1px solid $oc-gray-4;
  }

  &:focus,
  &:active {
    border: 1px solid $oc-wisteria-4;
  }

  &:invalid {
    background-color: $oc-red-1;
    border: 1px solid $oc-red-7;
  }
}
