@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.StyleGuide {
  &__example {
    display: flex;

    &__content {
      align-items: center;
      border: 1px solid $border;
      border-radius: 4px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      min-height: 256px;
      min-width: 256px;
    }
  }
}
