@import './scss/functions';
@import './scss/open-color';
@import './scss/variables';
@import './scss/mixins';
@import './scss/defaults';
@import './scss/typography';
@import './scss/utils';

body {
  @include scrollbar;
}

a {
  color: $primary;
  text-decoration: underline;
}

.divider {
  border: none;
  border-bottom: 1px solid $border;
  margin: 1rem 0;
}

// used for login, sign-up, and similar forms
.centerPage {
  max-width: 320px;
  margin: 3rem auto 1rem;
}
