@import 'src/scss/mixins.scss';
@import 'src/scss/variables.scss';

.LandingPage {
  &__main {
    background-color: $oc-gray-9;
    color: white;
    width: 100%;

    &__content {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 40px;
      justify-content: space-between;
      position: relative;
      padding-bottom: 248px;
    }

    &__logo {
      margin-top: 5rem;
    }

    &__title {
      font-size: 48px;
      margin: 0;
      max-width: 860px;
      text-align: center;
    }

    &__subtitle {
      color: $oc-fandango-5;
      height: 48px;
      margin-top: -16px;
      max-width: 860px;

      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__button {
      font-size: 20px !important;
      padding: 1rem 4rem !important;
      border-radius: 4rem !important;
    }

    &__carousel {
      position: absolute;
      bottom: -185px;
      z-index: 99;
    }
  }

  &__subscribe {
    width: 100%;

    &__content {
      align-items: center;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      margin-top: 20rem;
      margin-bottom: 8rem;
    }

    &__text {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 4px;

      h3 {
        color: $oc-gray-9;
        font-size: 36px;
        margin: 0;
      }
      p {
        color: $oc-gray-6;
        font-size: 20px;
        margin: 0;
      }
    }

    &__form {
      width: 420px;

      &__button {
        height: 52px;
      }

      &__inline {
        align-items: flex-start;
        display: flex;
        gap: 4px;
      }

      &__privacy {
        font-size: 12px;
        margin-top: 4px;
        color: $oc-gray-6;

        a {
          text-decoration: none;
          color: $oc-blue-5;
          cursor: pointer;
        }
      }
    }
  }

  &__promo {
    background-color: $oc-gray-9;
    color: white;
    width: 100%;

    &__content {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 1rem 0 22rem 0;
      position: relative;
      max-width: 650px;
      margin: 0 auto;
    }

    &__title {
      font-size: 48px;
      margin: 0;
      text-align: center;
    }

    &__subtitle {
      @include medium;
      color: $oc-gray-4;
      font-size: 20px;
      margin: 0;
      text-align: center;
    }

    &__buttons {
      padding-top: 2rem;
      display: flex;
      gap: 8px;
    }

    &__phones {
      position: absolute;
      bottom: -240px;
      z-index: 99;
    }
  }

  &__contact {
    margin: 14rem auto 8rem auto;
    max-width: 624px;

    &__content {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    &__title {
      color: $oc-gray-9;
      font-size: 48px;
      margin-bottom: 1rem;
      margin-top: 1.5rem;
      text-align: center;
    }

    &__subtitle {
      @include medium;
      color: $oc-gray-6;
      font-size: 20px;
      margin-bottom: 2rem;
      margin-top: 0;
      text-align: center;
    }

    &__form {
      padding: 2.5rem 4rem;
      background-color: $oc-gray-1;
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;

      div {
        label {
          display: block;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 4px !important;
        }
      }
    }
  }

  &__footer {
    background-color: $oc-gray-9;
    color: white;
    padding: 4rem 0;
    width: 100%;

    &__content {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    p {
      color: $oc-gray-4;
    }
  }
}

@include media-query('m') {
  .LandingPage {
    &__subscribe {
      &__content {
        flex-direction: column;
        gap: 1.5rem;
        margin-top: 14rem;
      }

      &__text {
        align-items: center;
        text-align: center;
      }
    }
  }
}

@include media-query('s') {
  .LandingPage {
    &__main {
      &__title {
        font-size: 30px;
      }
    }

    &__subscribe {
      &__text {
        gap: 8px;

        h3 {
          font-size: 30px;
        }

        p {
          font-size: 16px;
        }
      }
    }

    &__promo {
      &__title {
        font-size: 30px;
      }

      &__subtitle {
        font-size: 16px;
      }
    }

    &__contact {
      &__title {
        font-size: 30px;
      }

      &__subtitle {
        font-size: 16px;
      }

      &__form {
        padding: 1.25rem 2rem;
      }
    }
  }
}

@include media-query('xs') {
  .LandingPage {
    &__main {
      &__content {
        padding-bottom: 180px;
      }

      &__carousel {
        bottom: -125px;
      }
    }

    &__subscribe {
      &__form {
        width: 100%;
      }
    }

    &__promo {
      &__content {
        padding-bottom: 16rem;
      }

      &__phones {
        bottom: -120px;
      }

      &__buttons {
        flex-direction: column;
        gap: 8px;
      }
    }

    &__contact {
      &__form {
        padding: 1rem 1.5rem;
      }
    }

    &__footer {
      &__content {
        flex-direction: column;
        gap: 2rem;
      }
    }
  }
}
