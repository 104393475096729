@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Radio__container {
  cursor: pointer;
  display: block;
  height: 24px;
  line-height: 24px;
  margin-bottom: 0.5rem;
  padding-left: 2.5rem;
  position: relative;
  user-select: none;

  input {
    display: none;
  }

  &:hover {
    .Radio:not(:checked):not(:disabled) ~ .Radio__mark {
      border-color: $oc-gray-6;
    }
  }

  &:focus,
  &:active {
    .Radio:not(:checked):not(:disabled) ~ .Radio__mark {
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.Radio {
  &:checked ~ .Radio__mark {
    background-color: $primary;
    border-color: $primary;
  }

  &:checked ~ .Radio__mark:after {
    background-color: white;
    border-radius: 100%;
    content: '';
    height: 8px;
    width: 8px;
    position: absolute;
    top: 6px;
    left: 6px;
    transition: all 0.1s ease-in-out;
  }

  &:disabled ~ .Radio__mark {
    background-color: $oc-gray-3;
    border-color: $oc-gray-5;
    cursor: not-allowed;
  }
}

.Radio__mark {
  border: 2px solid $border;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s ease-in-out;
}
