@import '~/src/scss/mixins';
@import '~/src/scss/variables';

$colors: (
  'black': (
    'background-color': $oc-gray-7,
    'border-color': $oc-gray-9,
    'color': $oc-white,
  ),
  'red': (
    'background-color': $oc-red-0,
    'border-color': $oc-red-4,
    'color': $oc-red-8,
  ),
  'orange': (
    'background-color': $oc-orange-0,
    'border-color': $oc-orange-4,
    'color': $oc-orange-8,
  ),
  'yellow': (
    'background-color': $oc-yellow-0,
    'border-color': $oc-yellow-4,
    'color': $oc-yellow-8,
  ),
  'green': (
    'background-color': $oc-green-0,
    'border-color': $oc-green-4,
    'color': $oc-green-8,
  ),
  'blue': (
    'background-color': $oc-blue-0,
    'border-color': $oc-blue-4,
    'color': $oc-blue-8,
  ),
  'indigo': (
    'background-color': $oc-indigo-0,
    'border-color': $oc-indigo-4,
    'color': $oc-indigo-8,
  ),
  'violet': (
    'background-color': $oc-violet-0,
    'border-color': $oc-violet-4,
    'color': $oc-violet-8,
  ),
);

.Callout {
  background-color: $oc-gray-1;
  border: 1px solid $border;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  position: relative;
  width: 100%;

  &--withIcon {
    padding-left: 2rem;
  }

  &__header {
    @include bold;
    color: $oc-gray-8;
    margin-bottom: 4px;
  }

  &__content {
    color: $oc-gray-7;
  }

  &__icon {
    color: $oc-gray-8;
    font-size: 1.125rem !important;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;

    &--close {
      cursor: pointer;
      left: unset;
      right: 0.5rem;
    }
  }
}

// Colors

@each $name, $values in $colors {
  .Callout--#{$name} {
    background-color: map-get($values, 'background-color');
    border-color: map-get($values, 'border-color');

    .Callout__header,
    .Callout__content,
    .Callout__icon {
      color: map-get($values, 'color');
    }
  }
}
