@import 'src/scss/variables.scss';
@import 'src/scss/mixins.scss';

.PrivacyPolicyModal {
  width: 712px;
  height: calc(100vh - 128px);
  max-height: calc(100vh - 128px);
  top: 0px;
  margin: 64px auto;
  border: none;
  border-radius: 8px;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.04), 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  padding: 24px 0 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &::backdrop {
    background-color: rgba($oc-gray-5, 0.75) !important;
    backdrop-filter: blur(3px);
  }
  & .Dialog__close {
    color: $oc-gray-4;
    top: 0;
    right: 0;
  }
  &__innerScroll {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 24px 0 0;
  }
  & p {
    margin: 0;
    padding: 0;
  }
  & ul {
    margin: 0;
    padding: 0 0 0 24px;

    & > ul {
      list-style-type: disc;
    }
    & li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  a {
    color: $oc-blue-5;
    text-decoration: none;
  }
  &__title {
    font-size: 18px;
    color: $oc-gray-9;
    font-weight: 500;
  }
  &__subtitle {
    font-size: 14px;
    color: $oc-gray-7;
    @include bold;
  }
  &__classic {
    color: $oc-gray-7;
    font-size: 14px;
  }
  &__bold {
    @include bold;
  }
  &__emphasized {
    @include bold;
    font-style: italic;
  }
  &__italic {
    font-style: italic;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 56px;
  }
  &__section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__orderedList {
    display: flex;
    gap: 1rem;
    width: 100%;

    &__number {
      flex: 0 0 2rem;
      width: 2rem;
      @include bold;
    }
    &__content {
      flex: 1 0 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
  }
}
