@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.MultiSelect {
  position: relative;

  &__popover {
    @include scrollbar;
    max-height: 24rem;
    overflow-y: auto;
  }
}
