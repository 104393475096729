@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.TabItem {
  @include bold;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  color: $oc-gray-9;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  transition: color 0.1s ease-in-out, border 0.1s ease-in-out;

  &--active {
    border-bottom: 2px solid $primary;
    color: $primary;
  }
}
