.BasicInfoForm {
  &__row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__field {
    flex: 1;

    label {
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  &__actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
