// App-specific Typography
@mixin sans {
  font-family: Helvetica, Arial, sans-serif;
}

@mixin inter {
  font-family: 'Inter', sans-serif;
}

@mixin light {
  font-weight: 300;
}
@mixin normal {
  font-weight: 400;
}
@mixin medium {
  font-weight: 500;
}
@mixin semi-bold {
  font-weight: 600;
}
@mixin bold {
  font-weight: 700;
}

// non-standardized styling of placeholder text
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }
  @include optional-at-root(':-moz-placeholder') {
    @content;
  }
  @include optional-at-root('::-moz-placeholder') {
    @content;
  }
  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $oc-gray-3;
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $oc-gray-6;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $oc-gray-6;
  }
}

$breakpoints: (
  'xxs': 320px,
  'xs': 480px,
  's': 768px,
  'm': 1024px,
  'l': 1280px,
  'xl': 1440px,
  'xxl': 1920px,
);

@mixin media-query($size) {
  @if map_has_key($breakpoints, $size) {
    $width: map_get($breakpoints, $size);

    @media only screen and (max-width: $width) {
      @content;
    }
  }
}

// Responsive Typography
@mixin font-range($min-size, $max-size, $min-width: 320px, $max-width: 1920px) {
  $min-size-strip: strip-unit($min-size);
  $max-size-strip: strip-unit($max-size);
  $min-width-strip: strip-unit($min-width);
  $max-width-strip: strip-unit($max-width);

  & {
    font-size: $min-size-rem;
  }

  @media (min-width: $min-width-rem) and (max-width: $max-width-rem) {
    & {
      font-size: calc(
        #{$min-size} + (#{$max-size-strip} - #{$min-size-strip}) * (100vw - #{$min-width}) / (#{$max-width-strip} - #{$min-width-strip})
      );
    }
  }

  @media (min-width: $max-width-rem) {
    & {
      font-size: $max-size-rem;
    }
  }
}

@mixin size-range($property, $min-size, $max-size, $min-width: 320px, $max-width: 1920px) {
  $min-size-strip: strip-unit($min-size);
  $max-size-strip: strip-unit($max-size);
  $min-width-strip: strip-unit($min-width);
  $max-width-strip: strip-unit($max-width);

  & {
    #{$property}: $min-size;
  }

  @media (min-width: $min-width) and (max-width: $max-width) {
    & {
      #{$property}: calc(
        #{$min-size} + (#{$max-size-strip} - #{$min-size-strip}) * (100vw - #{$min-width}) / (#{$max-width-strip} - #{$min-width-strip})
      );
    }
  }

  @media (min-width: $max-width) {
    & {
      #{$property}: $max-size;
    }
  }
}
