// General typographic rules
html,
body {
  @include inter;
  @include normal;
  color: $oc-gray-9;
  font-size: 100%; // 16px in most browsers. If you change this value, you'll want to change the $rem-base variable in functions.scss
}

.error {
  @include bold;
  color: $oc-red-9;
  font-size: 14px;
}

.hint {
  @include normal;
  margin-top: 8px;
}

// Icon fonts
$font-path: '../assets/fonts';
