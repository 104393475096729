@import '~/src/scss/mixins';
@import '~/src/scss/variables';

$colors: (
  'black': (
    'main': $oc-gray-9,
    'text': white,
    'hover': $oc-gray-0,
    'active': $oc-gray-1,
    'raised-hover': $oc-gray-6,
  ),
  'white': (
    'main': white,
    'text': white,
    'hover': transparent,
    'active': transparent,
    'raised-hover': $oc-gray-2,
  ),
  'primary': (
    'main': $primary,
    'text': white,
    'hover': $primary-hover,
    'active': $primary-active,
    'raised-hover': $primary-raised-hover,
  ),
  'red': (
    'main': $oc-red-9,
    'text': white,
    'hover': $oc-red-0,
    'active': $oc-red-1,
    'raised-hover': $oc-red-6,
  ),
  'orange': (
    'main': $oc-orange-9,
    'text': white,
    'hover': $oc-orange-0,
    'active': $oc-orange-1,
    'raised-hover': $oc-orange-6,
  ),
  'yellow': (
    'main': $oc-yellow-6,
    'text': black,
    'hover': $oc-yellow-0,
    'active': $oc-yellow-1,
    'raised-hover': $oc-yellow-4,
  ),
  'green': (
    'main': $oc-green-9,
    'text': white,
    'hover': $oc-green-0,
    'active': $oc-green-1,
    'raised-hover': $oc-green-6,
  ),
  'blue': (
    'main': $oc-blue-9,
    'text': white,
    'hover': $oc-blue-0,
    'active': $oc-blue-1,
    'raised-hover': $oc-blue-6,
  ),
  'indigo': (
    'main': $oc-indigo-9,
    'text': white,
    'hover': $oc-indigo-0,
    'active': $oc-indigo-1,
    'raised-hover': $oc-indigo-6,
  ),
  'violet': (
    'main': $oc-violet-9,
    'text': white,
    'hover': $oc-violet-0,
    'active': $oc-violet-1,
    'raised-hover': $oc-violet-6,
  ),
  'fandango': (
    'main': $oc-fandango-9,
    'text': white,
    'hover': $oc-fandango-0,
    'active': $oc-fandango-1,
    'raised-hover': $oc-fandango-6,
  ),
);

@mixin button-base {
  @include medium;
  @include inter;
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: $oc-gray-9;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none !important;
  transition: all 0.1s ease-in-out;

  &:disabled {
    cursor: not-allowed;
  }

  .material-icons {
    display: block;
  }

  &.Button--fluid {
    width: 100%;
  }

  &.Button--size--sm {
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
  }

  &.Button--size--lg {
    font-size: 1rem;
    padding: 0.75rem 1.25rem;
  }

  &.Button--iconLeading {
    padding-left: 2.5rem !important;

    .iconLeading {
      font-size: 1.25rem;
      left: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translate(50%, -50%);
    }
  }

  &.Button--iconTrailing {
    padding-right: 2.5rem !important;

    .iconTrailing {
      font-size: 1.25rem;
      margin: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.Button--rounded {
    border-radius: 2rem !important;
  }
}

// Default variant

.Button--variant--default {
  @include button-base;

  &:hover {
    background-color: $oc-gray-0;
  }

  &:active {
    background-color: $oc-gray-1;
  }

  &:disabled {
    color: $oc-gray-5 !important;

    &:hover {
      background-color: transparent !important;
    }

    &:active {
      background-color: transparent !important;
    }
  }

  // colors

  @each $name, $values in $colors {
    &.Button--color--#{$name} {
      color: map-get($values, 'main');

      &:hover {
        background-color: map-get($values, 'hover');
      }

      &:active {
        background-color: map-get($values, 'active');
      }
    }
  }
}

// Outlined variant

.Button--variant--outlined {
  @include button-base;
  background-color: transparent;
  border: 2px solid $oc-gray-9;
  color: $oc-gray-9;

  &:hover {
    background-color: $oc-gray-0;
  }

  &:active {
    background-color: $oc-gray-1;
  }

  &:disabled {
    color: $oc-gray-5 !important;

    &:hover {
      background-color: transparent !important;
    }

    &:active {
      background-color: transparent !important;
    }
  }

  // colors

  @each $name, $values in $colors {
    &.Button--color--#{$name} {
      border-color: map-get($values, 'main');
      color: map-get($values, 'main');

      &:hover {
        background-color: map-get($values, 'hover');
      }

      &:active {
        background-color: map-get($values, 'active');
      }
    }
  }
}

// Raised variant

.Button--variant--raised {
  @include button-base;

  &:disabled {
    background-color: $oc-gray-0 !important;
    color: $oc-gray-5 !important;
  }

  // colors

  @each $name, $values in $colors {
    &.Button--color--#{$name} {
      background-color: map-get($values, 'main');
      color: map-get($values, 'text');

      &:not(:disabled):hover {
        background-color: map-get($values, 'raised-hover');
      }
    }
  }
}
