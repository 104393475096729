@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Toaster {
  top: 72px;
  right: 48px;
  max-width: 350px;
  min-width: 350px;
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__toast {
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 8px 16px 0 rgba(0, 0, 0, 0.14);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;
      flex-shrink: 0;
    }

    &__message {
      margin: 0;
      font-size: 14px;
      flex-grow: 1;

      &__line {
        margin: 0;
      }
    }

    &--success {
      background-color: $oc-green-0;
      color: $oc-green-9;
    }

    &--error {
      background-color: $oc-red-0;
      color: $oc-red-9;
    }

    &--warning {
      background-color: $oc-yellow-0;
      color: $oc-yellow-9;
    }

    &--info {
      background-color: $oc-blue-0;
      color: $oc-blue-9;
    }
  }
}
