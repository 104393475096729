@import 'src/scss/open-color.scss';

.LandingPage__wave {
  width: 100%;
  background-color: $oc-gray-9;
  clip-path: url(#wave);
  margin: -2px 0 0 0;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
