.AnimatedSubtitle {
  &__words {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
  }

  &__word {
    animation: revealUp 0.2s ease-in-out forwards;
    font-size: 20px;
    opacity: 0;
  }

  @keyframes revealUp {
    0% {
      opacity: 10%;
      transform: translateY(8px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
