@import 'src/scss/open-color.scss';

.Carousel {
  --duration: 0s;
  --transition-duration: 0s;
  position: relative;
  width: 100%;

  &__slide {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: max-content;
    width: max-content;
    max-width: 100%;
    border-radius: 24px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1), 0 20px 25px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: none;
    animation: fadeIn var(--transition-duration) ease-in-out,
      fadeOut var(--transition-duration) ease-in-out var(--duration) forwards;

    &--current {
      display: flex;
    }
  }

  &__dots {
    display: flex;
    gap: 8px;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__dot {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;
    height: 8px;
    width: 8px;
    transition: background-color 0.2s ease-in-out;

    &--active {
      background-color: white;
      border: 1px solid white;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
