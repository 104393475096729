.block {
  display: block;
}

.relative {
  position: relative;
}

// shadow

.shadow {
  &-none {
    box-shadow: none;
  }
  &-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  }
  &-md {
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  }
  &-lg {
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.25);
  }
}

// image

.image {
  &-invert {
    filter: invert(1);
  }
}

// text

$text-colors: (
  'gray-dk': $oc-gray-9,
  'gray': $oc-gray-6,
  'gray-lt': $oc-gray-3,
  'primary': $primary,
);

$text-sizes: (
  'xxs': 0.625rem,
  'xs': 0.75rem,
  's': 0.875rem,
  'm': 1rem,
  'l': 1.25rem,
  'xl': 1.5rem,
  'xxl': 2rem,
);

$text-weights: (
  'light': 300,
  'normal': 400,
  'medium': 500,
  'semi-bold': 600,
  'bold': 700,
);

.text {
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-center {
    text-align: center;
  }
  &-nowrap {
    white-space: nowrap;
  }
  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-uppercase {
    text-transform: uppercase;
  }
  &-lowercase {
    text-transform: lowercase;
  }
  &-capitalize {
    text-transform: capitalize;
  }
  @each $name, $value in $text-colors {
    &-color-#{$name} {
      color: $value;
    }
  }
  @each $name, $value in $text-sizes {
    &-size-#{$name} {
      font-size: $value;
    }
  }
  @each $name, $value in $text-weights {
    &-weight-#{$name} {
      font-weight: $value;
    }
  }
}

// Flexbox

.flex {
  display: flex;

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column;
  }
}

// Align

.align {
  &-center {
    align-items: center;
  }
  &-start {
    align-items: flex-start;
  }
  &-end {
    align-items: flex-end;
  }
}

// Justify

.justify {
  &-center {
    justify-content: center;
  }
  &-start {
    justify-content: flex-start;
  }
  &-end {
    justify-content: flex-end;
  }
  &-between {
    justify-content: space-between;
  }
  &-around {
    justify-content: space-around;
  }
  &-evenly {
    justify-content: space-evenly;
  }
}

// flex 1-12

@for $i from 1 through 12 {
  .flex-#{$i} {
    flex: $i;
  }
}

// gap 1-12

@for $i from 1 through 12 {
  .gap-#{$i} {
    gap: $i * 0.25rem;
  }
}

// padding 0-12

@for $i from 0 through 12 {
  .pt-#{$i} {
    padding-top: $i * 0.25rem;
  }
  .pb-#{$i} {
    padding-bottom: $i * 0.25rem;
  }
  .pl-#{$i} {
    padding-left: $i * 0.25rem;
  }
  .pr-#{$i} {
    padding-right: $i * 0.25rem;
  }
  .px-#{$i} {
    padding-left: $i * 0.25rem;
    padding-right: $i * 0.25rem;
  }
  .py-#{$i} {
    padding-top: $i * 0.25rem;
    padding-bottom: $i * 0.25rem;
  }
  .p-#{$i} {
    padding: $i * 0.25rem;
  }
}

// margin 0-12

@for $i from 0 through 12 {
  .mt-#{$i} {
    margin-top: $i * 0.25rem;
  }
  .mb-#{$i} {
    margin-bottom: $i * 0.25rem;
  }
  .ml-#{$i} {
    margin-left: $i * 0.25rem;
  }
  .mr-#{$i} {
    margin-right: $i * 0.25rem;
  }
  .mx-#{$i} {
    margin-left: $i * 0.25rem;
    margin-right: $i * 0.25rem;
  }
  .my-#{$i} {
    margin-top: $i * 0.25rem;
    margin-bottom: $i * 0.25rem;
  }
  .m-#{$i} {
    margin: $i * 0.25rem;
  }
}
