@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Menu {
  background-color: white;
  border: 1px solid $border;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-width: 10rem;
  padding: 0.5rem 0;
}
