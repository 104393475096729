* {
  box-sizing: border-box;
}

body {
  background-color: #fff;
  min-width: 320px;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

input[type="submit"] {
  cursor: pointer;
}

input[type="button"] {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

input[type="submit"]:disabled {
  cursor: not-allowed;
}

input[type="button"]:disabled {
  cursor: not-allowed;
}

img, video, iframe {
  max-width: 100%;
}

table {
  font-size: inherit;
}

html, body {
  color: #111827;
  font-family: Inter, sans-serif;
  font-size: 100%;
  font-weight: 400;
}

.error {
  color: #c92a2a;
  font-size: 14px;
  font-weight: 700;
}

.hint {
  margin-top: 8px;
  font-weight: 400;
}

.block {
  display: block;
}

.relative {
  position: relative;
}

.shadow-none {
  box-shadow: none;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25);
}

.shadow-md {
  box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .25);
}

.shadow-lg {
  box-shadow: 0 .5rem 2rem rgba(0, 0, 0, .25);
}

.image-invert {
  filter: invert();
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-nowrap {
  white-space: nowrap;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-color-gray-dk {
  color: #111827;
}

.text-color-gray {
  color: #4b5563;
}

.text-color-gray-lt {
  color: #d4d4d8;
}

.text-color-primary {
  color: #731c5d;
}

.text-size-xxs {
  font-size: .625rem;
}

.text-size-xs {
  font-size: .75rem;
}

.text-size-s {
  font-size: .875rem;
}

.text-size-m {
  font-size: 1rem;
}

.text-size-l {
  font-size: 1.25rem;
}

.text-size-xl {
  font-size: 1.5rem;
}

.text-size-xxl {
  font-size: 2rem;
}

.text-weight-light {
  font-weight: 300;
}

.text-weight-normal {
  font-weight: 400;
}

.text-weight-medium {
  font-weight: 500;
}

.text-weight-semi-bold {
  font-weight: 600;
}

.text-weight-bold {
  font-weight: 700;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 10;
}

.flex-11 {
  flex: 11;
}

.flex-12 {
  flex: 12;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-11 {
  gap: 2.75rem;
}

.gap-12 {
  gap: 3rem;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-0 {
  padding: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pr-1 {
  padding-right: .25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.p-1 {
  padding: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.p-2 {
  padding: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-3 {
  padding-right: .75rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.p-3 {
  padding: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-4 {
  padding: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.p-5 {
  padding: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.p-6 {
  padding: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.p-7 {
  padding: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-8 {
  padding-right: 2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-8 {
  padding: 2rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.p-9 {
  padding: 2.25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.p-10 {
  padding: 2.5rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.p-11 {
  padding: 2.75rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.p-12 {
  padding: 3rem;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-0 {
  margin: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.m-1 {
  margin: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.m-2 {
  margin: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.m-3 {
  margin: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.m-4 {
  margin: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.m-5 {
  margin: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.m-6 {
  margin: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.m-7 {
  margin: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.m-8 {
  margin: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.m-9 {
  margin: 2.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.m-10 {
  margin: 2.5rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.ml-11 {
  margin-left: 2.75rem;
}

.mr-11 {
  margin-right: 2.75rem;
}

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.m-11 {
  margin: 2.75rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.ml-12 {
  margin-left: 3rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.m-12 {
  margin: 3rem;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-track {
  background: #d4d4d8;
  border-radius: 8px;
}

body::-webkit-scrollbar-thumb {
  background: #4b5563;
  border-radius: 8px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #4b5563;
}

a {
  color: #731c5d;
  text-decoration: underline;
}

.divider {
  border: none;
  border-bottom: 1px solid #d4d4d8;
  margin: 1rem 0;
}

.centerPage {
  max-width: 320px;
  margin: 3rem auto 1rem;
}

.Badge {
  color: #1f2937;
  cursor: pointer;
  background-color: #f3f4f6;
  border-radius: 2rem;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  padding: .25rem .5rem;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.Badge .material-icons, .Badge .material-symbols, .Badge .material-symbols-outlined {
  margin-right: 2px;
  font-size: 16px;
}

.Badge--black {
  color: #f3f4f6;
  background-color: #1f2937;
}

.Badge--red {
  color: #e03131;
  background-color: #ffe3e3;
}

.Badge--orange {
  color: #e8590c;
  background-color: #ffe8cc;
}

.Badge--yellow {
  color: #f08c00;
  background-color: #fff3bf;
}

.Badge--green {
  color: #0a5b39;
  background-color: #d3f8e0;
}

.Badge--blue {
  color: #1b6ec2;
  background-color: #ccedff;
}

.Badge--indigo {
  color: #3b5bdb;
  background-color: #dbe4ff;
}

.Badge--violet {
  color: #6741d9;
  background-color: #e5dbff;
}

.Badge--endeavour {
  color: #faf6fe;
  background-color: #7738b8;
}

.Button--variant--default {
  -webkit-appearance: none;
  appearance: none;
  color: #111827;
  cursor: pointer;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 4px;
  padding: .5rem 1rem;
  font-family: Inter, sans-serif;
  font-size: .875rem;
  font-weight: 500;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
  text-decoration: none !important;
}

.Button--variant--default:disabled {
  cursor: not-allowed;
}

.Button--variant--default .material-icons {
  display: block;
}

.Button--variant--default.Button--fluid {
  width: 100%;
}

.Button--variant--default.Button--size--sm {
  padding: .25rem .75rem;
  font-size: .75rem;
}

.Button--variant--default.Button--size--lg {
  padding: .75rem 1.25rem;
  font-size: 1rem;
}

.Button--variant--default.Button--iconLeading {
  padding-left: 2.5rem !important;
}

.Button--variant--default.Button--iconLeading .iconLeading {
  margin: 0;
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(50%, -50%);
}

.Button--variant--default.Button--iconTrailing {
  padding-right: 2.5rem !important;
}

.Button--variant--default.Button--iconTrailing .iconTrailing {
  margin: 0;
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.Button--variant--default.Button--rounded {
  border-radius: 2rem !important;
}

.Button--variant--default:hover {
  background-color: #f9fafb;
}

.Button--variant--default:active {
  background-color: #f3f4f6;
}

.Button--variant--default:disabled {
  color: #6b7280 !important;
}

.Button--variant--default:disabled:hover, .Button--variant--default:disabled:active {
  background-color: rgba(0, 0, 0, 0) !important;
}

.Button--variant--default.Button--color--black {
  color: #111827;
}

.Button--variant--default.Button--color--black:hover {
  background-color: #f9fafb;
}

.Button--variant--default.Button--color--black:active {
  background-color: #f3f4f6;
}

.Button--variant--default.Button--color--white {
  color: #fff;
}

.Button--variant--default.Button--color--white:hover, .Button--variant--default.Button--color--white:active {
  background-color: rgba(0, 0, 0, 0);
}

.Button--variant--default.Button--color--primary {
  color: #731c5d;
}

.Button--variant--default.Button--color--primary:hover {
  background-color: #fff4fe;
}

.Button--variant--default.Button--color--primary:active {
  background-color: #fee9fc;
}

.Button--variant--default.Button--color--red {
  color: #c92a2a;
}

.Button--variant--default.Button--color--red:hover {
  background-color: #fff5f5;
}

.Button--variant--default.Button--color--red:active {
  background-color: #ffe3e3;
}

.Button--variant--default.Button--color--orange {
  color: #d9480f;
}

.Button--variant--default.Button--color--orange:hover {
  background-color: #fff4e6;
}

.Button--variant--default.Button--color--orange:active {
  background-color: #ffe8cc;
}

.Button--variant--default.Button--color--yellow {
  color: #fab005;
}

.Button--variant--default.Button--color--yellow:hover {
  background-color: #fff9db;
}

.Button--variant--default.Button--color--yellow:active {
  background-color: #fff3bf;
}

.Button--variant--default.Button--color--green {
  color: #094b30;
}

.Button--variant--default.Button--color--green:hover {
  background-color: #edfcf3;
}

.Button--variant--default.Button--color--green:active {
  background-color: #d3f8e0;
}

.Button--variant--default.Button--color--blue {
  color: #1862ab;
}

.Button--variant--default.Button--color--blue:hover {
  background-color: #e8f7ff;
}

.Button--variant--default.Button--color--blue:active {
  background-color: #ccedff;
}

.Button--variant--default.Button--color--indigo {
  color: #364fc7;
}

.Button--variant--default.Button--color--indigo:hover {
  background-color: #edf2ff;
}

.Button--variant--default.Button--color--indigo:active {
  background-color: #dbe4ff;
}

.Button--variant--default.Button--color--violet {
  color: #5f3dc4;
}

.Button--variant--default.Button--color--violet:hover {
  background-color: #f3f0ff;
}

.Button--variant--default.Button--color--violet:active {
  background-color: #e5dbff;
}

.Button--variant--default.Button--color--fandango {
  color: #731c5d;
}

.Button--variant--default.Button--color--fandango:hover {
  background-color: #fff4fe;
}

.Button--variant--default.Button--color--fandango:active {
  background-color: #fee9fc;
}

.Button--variant--outlined {
  -webkit-appearance: none;
  appearance: none;
  color: #111827;
  cursor: pointer;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #111827;
  border-radius: 4px;
  padding: .5rem 1rem;
  font-family: Inter, sans-serif;
  font-size: .875rem;
  font-weight: 500;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
  text-decoration: none !important;
}

.Button--variant--outlined:disabled {
  cursor: not-allowed;
}

.Button--variant--outlined .material-icons {
  display: block;
}

.Button--variant--outlined.Button--fluid {
  width: 100%;
}

.Button--variant--outlined.Button--size--sm {
  padding: .25rem .75rem;
  font-size: .75rem;
}

.Button--variant--outlined.Button--size--lg {
  padding: .75rem 1.25rem;
  font-size: 1rem;
}

.Button--variant--outlined.Button--iconLeading {
  padding-left: 2.5rem !important;
}

.Button--variant--outlined.Button--iconLeading .iconLeading {
  margin: 0;
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(50%, -50%);
}

.Button--variant--outlined.Button--iconTrailing {
  padding-right: 2.5rem !important;
}

.Button--variant--outlined.Button--iconTrailing .iconTrailing {
  margin: 0;
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.Button--variant--outlined.Button--rounded {
  border-radius: 2rem !important;
}

.Button--variant--outlined:hover {
  background-color: #f9fafb;
}

.Button--variant--outlined:active {
  background-color: #f3f4f6;
}

.Button--variant--outlined:disabled {
  color: #6b7280 !important;
}

.Button--variant--outlined:disabled:hover, .Button--variant--outlined:disabled:active {
  background-color: rgba(0, 0, 0, 0) !important;
}

.Button--variant--outlined.Button--color--black {
  color: #111827;
  border-color: #111827;
}

.Button--variant--outlined.Button--color--black:hover {
  background-color: #f9fafb;
}

.Button--variant--outlined.Button--color--black:active {
  background-color: #f3f4f6;
}

.Button--variant--outlined.Button--color--white {
  color: #fff;
  border-color: #fff;
}

.Button--variant--outlined.Button--color--white:hover, .Button--variant--outlined.Button--color--white:active {
  background-color: rgba(0, 0, 0, 0);
}

.Button--variant--outlined.Button--color--primary {
  color: #731c5d;
  border-color: #731c5d;
}

.Button--variant--outlined.Button--color--primary:hover {
  background-color: #fff4fe;
}

.Button--variant--outlined.Button--color--primary:active {
  background-color: #fee9fc;
}

.Button--variant--outlined.Button--color--red {
  color: #c92a2a;
  border-color: #c92a2a;
}

.Button--variant--outlined.Button--color--red:hover {
  background-color: #fff5f5;
}

.Button--variant--outlined.Button--color--red:active {
  background-color: #ffe3e3;
}

.Button--variant--outlined.Button--color--orange {
  color: #d9480f;
  border-color: #d9480f;
}

.Button--variant--outlined.Button--color--orange:hover {
  background-color: #fff4e6;
}

.Button--variant--outlined.Button--color--orange:active {
  background-color: #ffe8cc;
}

.Button--variant--outlined.Button--color--yellow {
  color: #fab005;
  border-color: #fab005;
}

.Button--variant--outlined.Button--color--yellow:hover {
  background-color: #fff9db;
}

.Button--variant--outlined.Button--color--yellow:active {
  background-color: #fff3bf;
}

.Button--variant--outlined.Button--color--green {
  color: #094b30;
  border-color: #094b30;
}

.Button--variant--outlined.Button--color--green:hover {
  background-color: #edfcf3;
}

.Button--variant--outlined.Button--color--green:active {
  background-color: #d3f8e0;
}

.Button--variant--outlined.Button--color--blue {
  color: #1862ab;
  border-color: #1862ab;
}

.Button--variant--outlined.Button--color--blue:hover {
  background-color: #e8f7ff;
}

.Button--variant--outlined.Button--color--blue:active {
  background-color: #ccedff;
}

.Button--variant--outlined.Button--color--indigo {
  color: #364fc7;
  border-color: #364fc7;
}

.Button--variant--outlined.Button--color--indigo:hover {
  background-color: #edf2ff;
}

.Button--variant--outlined.Button--color--indigo:active {
  background-color: #dbe4ff;
}

.Button--variant--outlined.Button--color--violet {
  color: #5f3dc4;
  border-color: #5f3dc4;
}

.Button--variant--outlined.Button--color--violet:hover {
  background-color: #f3f0ff;
}

.Button--variant--outlined.Button--color--violet:active {
  background-color: #e5dbff;
}

.Button--variant--outlined.Button--color--fandango {
  color: #731c5d;
  border-color: #731c5d;
}

.Button--variant--outlined.Button--color--fandango:hover {
  background-color: #fff4fe;
}

.Button--variant--outlined.Button--color--fandango:active {
  background-color: #fee9fc;
}

.Button--variant--raised {
  -webkit-appearance: none;
  appearance: none;
  color: #111827;
  cursor: pointer;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 4px;
  padding: .5rem 1rem;
  font-family: Inter, sans-serif;
  font-size: .875rem;
  font-weight: 500;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
  text-decoration: none !important;
}

.Button--variant--raised:disabled {
  cursor: not-allowed;
}

.Button--variant--raised .material-icons {
  display: block;
}

.Button--variant--raised.Button--fluid {
  width: 100%;
}

.Button--variant--raised.Button--size--sm {
  padding: .25rem .75rem;
  font-size: .75rem;
}

.Button--variant--raised.Button--size--lg {
  padding: .75rem 1.25rem;
  font-size: 1rem;
}

.Button--variant--raised.Button--iconLeading {
  padding-left: 2.5rem !important;
}

.Button--variant--raised.Button--iconLeading .iconLeading {
  margin: 0;
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(50%, -50%);
}

.Button--variant--raised.Button--iconTrailing {
  padding-right: 2.5rem !important;
}

.Button--variant--raised.Button--iconTrailing .iconTrailing {
  margin: 0;
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.Button--variant--raised.Button--rounded {
  border-radius: 2rem !important;
}

.Button--variant--raised:disabled {
  color: #6b7280 !important;
  background-color: #f9fafb !important;
}

.Button--variant--raised.Button--color--black {
  color: #fff;
  background-color: #111827;
}

.Button--variant--raised.Button--color--black:not(:disabled):hover {
  background-color: #4b5563;
}

.Button--variant--raised.Button--color--white {
  color: #fff;
  background-color: #fff;
}

.Button--variant--raised.Button--color--white:not(:disabled):hover {
  background-color: #e5e7eb;
}

.Button--variant--raised.Button--color--primary {
  color: #fff;
  background-color: #731c5d;
}

.Button--variant--raised.Button--color--primary:not(:disabled):hover {
  background-color: #cf2aae;
}

.Button--variant--raised.Button--color--red {
  color: #fff;
  background-color: #c92a2a;
}

.Button--variant--raised.Button--color--red:not(:disabled):hover {
  background-color: #fa5252;
}

.Button--variant--raised.Button--color--orange {
  color: #fff;
  background-color: #d9480f;
}

.Button--variant--raised.Button--color--orange:not(:disabled):hover {
  background-color: #fd7e14;
}

.Button--variant--raised.Button--color--yellow {
  color: #000;
  background-color: #fab005;
}

.Button--variant--raised.Button--color--yellow:not(:disabled):hover {
  background-color: #ffd43b;
}

.Button--variant--raised.Button--color--green {
  color: #fff;
  background-color: #094b30;
}

.Button--variant--raised.Button--color--green:not(:disabled):hover {
  background-color: #0b9054;
}

.Button--variant--raised.Button--color--blue {
  color: #fff;
  background-color: #1862ab;
}

.Button--variant--raised.Button--color--blue:not(:disabled):hover {
  background-color: #228ae6;
}

.Button--variant--raised.Button--color--indigo {
  color: #fff;
  background-color: #364fc7;
}

.Button--variant--raised.Button--color--indigo:not(:disabled):hover {
  background-color: #4c6ef5;
}

.Button--variant--raised.Button--color--violet {
  color: #fff;
  background-color: #5f3dc4;
}

.Button--variant--raised.Button--color--violet:not(:disabled):hover {
  background-color: #7950f2;
}

.Button--variant--raised.Button--color--fandango {
  color: #fff;
  background-color: #731c5d;
}

.Button--variant--raised.Button--color--fandango:not(:disabled):hover {
  background-color: #cf2aae;
}

.Callout {
  background-color: #f3f4f6;
  border: 1px solid #d4d4d8;
  border-radius: 4px;
  width: 100%;
  padding: .5rem 1rem;
  position: relative;
}

.Callout--withIcon {
  padding-left: 2rem;
}

.Callout__header {
  color: #1f2937;
  margin-bottom: 4px;
  font-weight: 700;
}

.Callout__content {
  color: #374151;
}

.Callout__icon {
  color: #1f2937;
  position: absolute;
  top: .5rem;
  left: .5rem;
  font-size: 1.125rem !important;
}

.Callout__icon--close {
  cursor: pointer;
  left: unset;
  right: .5rem;
}

.Callout--black {
  background-color: #374151;
  border-color: #111827;
}

.Callout--black .Callout__header, .Callout--black .Callout__content, .Callout--black .Callout__icon {
  color: #fff;
}

.Callout--red {
  background-color: #fff5f5;
  border-color: #ff8787;
}

.Callout--red .Callout__header, .Callout--red .Callout__content, .Callout--red .Callout__icon {
  color: #e03131;
}

.Callout--orange {
  background-color: #fff4e6;
  border-color: #ffa94d;
}

.Callout--orange .Callout__header, .Callout--orange .Callout__content, .Callout--orange .Callout__icon {
  color: #e8590c;
}

.Callout--yellow {
  background-color: #fff9db;
  border-color: #ffd43b;
}

.Callout--yellow .Callout__header, .Callout--yellow .Callout__content, .Callout--yellow .Callout__icon {
  color: #f08c00;
}

.Callout--green {
  background-color: #edfcf3;
  border-color: #3acd82;
}

.Callout--green .Callout__header, .Callout--green .Callout__content, .Callout--green .Callout__icon {
  color: #0a5b39;
}

.Callout--blue {
  background-color: #e8f7ff;
  border-color: #4dadf7;
}

.Callout--blue .Callout__header, .Callout--blue .Callout__content, .Callout--blue .Callout__icon {
  color: #1b6ec2;
}

.Callout--indigo {
  background-color: #edf2ff;
  border-color: #748ffc;
}

.Callout--indigo .Callout__header, .Callout--indigo .Callout__content, .Callout--indigo .Callout__icon {
  color: #3b5bdb;
}

.Callout--violet {
  background-color: #f3f0ff;
  border-color: #9775fa;
}

.Callout--violet .Callout__header, .Callout--violet .Callout__content, .Callout--violet .Callout__icon {
  color: #6741d9;
}

.Card {
  background-color: #fff;
  border: 1px solid #d4d4d8;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 240px;
  margin: 1rem 0;
  padding: 1rem;
}

.Card.Card--fluid {
  width: 100%;
}

.Card.Card--interactive {
  cursor: pointer;
}

.Card.Card--rounded {
  border-radius: 8px;
}

.Checkbox__container {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  min-height: 24px;
  margin-bottom: .5rem;
  padding-left: 2.5rem;
  line-height: 24px;
  display: block;
  position: relative;
}

.Checkbox__container input {
  display: none;
}

.Checkbox__container:hover .Checkbox:not(:checked):not(:disabled) ~ .Checkbox__mark {
  border-color: #4b5563;
}

.Checkbox__container:focus .Checkbox:not(:checked):not(:disabled) ~ .Checkbox__mark, .Checkbox__container:active .Checkbox:not(:checked):not(:disabled) ~ .Checkbox__mark, .Checkbox:checked ~ .Checkbox__mark {
  border-color: #731c5d;
}

.Checkbox:checked ~ .Checkbox__mark:after {
  content: "";
  border-bottom: 2px solid #731c5d;
  border-left: 2px solid #731c5d;
  width: 12px;
  height: 4px;
  position: absolute;
  top: 6px;
  left: 3px;
  transform: rotate(-45deg);
}

.Checkbox:disabled ~ .Checkbox__mark {
  cursor: not-allowed;
  border-color: #6b7280;
}

.Checkbox__mark {
  color: #731c5d;
  border: 2px solid #d4d4d8;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.Container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}

.IconButton {
  -webkit-appearance: none;
  appearance: none;
  color: #111827;
  cursor: pointer;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 100%;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 0;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
  text-decoration: none !important;
}

.IconButton:disabled {
  cursor: not-allowed;
}

.IconButton .material-icons {
  display: block;
}

.IconButton:hover {
  background-color: #f9fafb;
}

.IconButton:active {
  background-color: #f3f4f6;
}

.IconButton:disabled {
  color: #6b7280 !important;
}

.IconButton:disabled:hover, .IconButton:disabled:active {
  background-color: rgba(0, 0, 0, 0) !important;
}

.IconButton.IconButton--color--black {
  color: #111827;
}

.IconButton.IconButton--color--black:hover {
  background-color: #f9fafb;
}

.IconButton.IconButton--color--black:active {
  background-color: #f3f4f6;
}

.IconButton.IconButton--color--primary {
  color: #731c5d;
}

.IconButton.IconButton--color--primary:hover {
  background-color: #fff4fe;
}

.IconButton.IconButton--color--primary:active {
  background-color: #fee9fc;
}

.IconButton.IconButton--color--red {
  color: #c92a2a;
}

.IconButton.IconButton--color--red:hover {
  background-color: #fff5f5;
}

.IconButton.IconButton--color--red:active {
  background-color: #ffe3e3;
}

.IconButton.IconButton--color--orange {
  color: #d9480f;
}

.IconButton.IconButton--color--orange:hover {
  background-color: #fff4e6;
}

.IconButton.IconButton--color--orange:active {
  background-color: #ffe8cc;
}

.IconButton.IconButton--color--yellow {
  color: #fab005;
}

.IconButton.IconButton--color--yellow:hover {
  background-color: #fff9db;
}

.IconButton.IconButton--color--yellow:active {
  background-color: #fff3bf;
}

.IconButton.IconButton--color--green {
  color: #094b30;
}

.IconButton.IconButton--color--green:hover {
  background-color: #edfcf3;
}

.IconButton.IconButton--color--green:active {
  background-color: #d3f8e0;
}

.IconButton.IconButton--color--blue {
  color: #1862ab;
}

.IconButton.IconButton--color--blue:hover {
  background-color: #e8f7ff;
}

.IconButton.IconButton--color--blue:active {
  background-color: #ccedff;
}

.IconButton.IconButton--color--indigo {
  color: #364fc7;
}

.IconButton.IconButton--color--indigo:hover {
  background-color: #edf2ff;
}

.IconButton.IconButton--color--indigo:active {
  background-color: #dbe4ff;
}

.IconButton.IconButton--color--violet {
  color: #5f3dc4;
}

.IconButton.IconButton--color--violet:hover {
  background-color: #f3f0ff;
}

.IconButton.IconButton--color--violet:active {
  background-color: #e5dbff;
}

.Input__container {
  display: inline-block;
  position: relative;
}

.Input__container.Input__container--fluid {
  width: 100%;
}

.Input__container .material-icons {
  display: block;
}

.Input__container .iconLeading {
  margin: 0;
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(50%, -50%);
}

.Input__container .iconTrailing {
  margin: 0;
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.Input {
  background: #fff;
  border: 1px solid #d4d4d8;
  border-radius: 12px;
  outline: none;
  padding: 16px;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  transition: all .1s ease-in-out;
}

.Input::-webkit-input-placeholder {
  color: #4b5563;
}

.Input:-moz-placeholder {
  color: #4b5563;
}

.Input::-moz-placeholder {
  color: #4b5563;
}

.Input:-ms-input-placeholder {
  color: #4b5563;
}

.Input:disabled {
  cursor: not-allowed;
}

.Input.Input--fluid {
  width: 100%;
}

.Input.Input--rounded {
  border-radius: 2rem;
}

.Input.Input--iconLeading {
  padding-left: 2.5rem !important;
}

.Input.Input--iconTrailing {
  padding-right: 2.5rem !important;
}

.Input--textarea {
  resize: none;
  max-width: 100%;
  min-height: 8rem;
  padding: 1rem;
}

.Input.Input--size--sm {
  padding: .75rem;
  font-size: .75rem;
}

.Input.Input--size--lg {
  padding: 1.25rem;
  font-size: 1.25rem;
}

.Input:hover {
  border: 1px solid #9ca3af;
}

.Input:focus, .Input:active {
  border: 1px solid #c3b0d5;
}

.Input:invalid {
  background-color: #ffe3e3;
  border: 1px solid #f03e3e;
}

.Popover {
  z-index: 101;
  background-color: #fff;
  border: 1px solid #d4d4d8;
  border-radius: 4px;
  padding: .5rem 1rem;
  animation: .4s scaleIn;
  position: absolute;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25);
}

.Popover__overlay {
  z-index: 100;
  position: fixed;
  inset: 0;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.DatePicker__container {
  position: relative;
}

.DatePicker__navigation {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DatePicker__month {
  margin: 0 1rem;
}

.DatePicker__calendar {
  margin: 1rem 0;
}

.DatePicker__calendar table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.DatePicker__calendar table thead th {
  color: #4b5563;
  font-size: 14px;
  font-weight: 700;
}

.DatePicker__calendar table tbody td {
  color: #111827;
  cursor: pointer;
  text-align: center;
  padding: .5rem 0;
  font-size: 12px;
}

.DatePicker__selected {
  position: relative;
  color: #fff !important;
}

.DatePicker__selected:before {
  content: "";
  z-index: -1;
  background-color: #731c5d;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
}

.Dialog {
  border: none;
  border-radius: 8px;
  min-width: 16rem;
  padding: 1.5rem;
  animation: .6s scaleIn;
  position: relative;
  box-shadow: 0 .5rem 2rem rgba(0, 0, 0, .25);
}

.Dialog::backdrop {
  background-color: rgba(0, 0, 0, 0);
}

.Dialog--backdrop::backdrop {
  background-color: rgba(17, 24, 39, .7) !important;
}

.Dialog__close {
  top: .5rem;
  right: .5rem;
  position: absolute !important;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInTop {
  0% {
    transform: translateY(-100vh);
  }

  70% {
    transform: translateY(5vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInBottom {
  0% {
    transform: translateY(100vh);
  }

  70% {
    transform: translateY(-5vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100vw);
  }

  70% {
    transform: translateX(5vw);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100vw);
  }

  70% {
    transform: translateX(-5vw);
  }

  100% {
    transform: translateX(0);
  }
}

.Drawer {
  z-index: 101;
  background-color: #fff;
  min-width: 24rem;
  height: 100vh;
  animation: .4s slideInRight;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 0 .5rem 2rem rgba(0, 0, 0, .25);
}

.Drawer__overlay {
  z-index: 100;
  position: fixed;
  inset: 0;
}

.Drawer__overlay--bg {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(107, 114, 128, .75);
}

@keyframes slideInRight {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0);
  }
}

.Menu {
  background-color: #fff;
  border: 1px solid #d4d4d8;
  border-radius: 4px;
  flex-direction: column;
  min-width: 10rem;
  padding: .5rem 0;
  display: flex;
}



.MultiSelect {
  position: relative;
}

.MultiSelect__popover {
  max-height: 24rem;
  overflow-y: auto;
}

.MultiSelect__popover::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.MultiSelect__popover::-webkit-scrollbar-track {
  background: #d4d4d8;
  border-radius: 8px;
}

.MultiSelect__popover::-webkit-scrollbar-thumb {
  background: #4b5563;
  border-radius: 8px;
}

.MultiSelect__popover::-webkit-scrollbar-thumb:hover {
  background: #4b5563;
}

.Radio__container {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 24px;
  margin-bottom: .5rem;
  padding-left: 2.5rem;
  line-height: 24px;
  display: block;
  position: relative;
}

.Radio__container input {
  display: none;
}

.Radio__container:hover .Radio:not(:checked):not(:disabled) ~ .Radio__mark {
  border-color: #4b5563;
}

.Radio__container:focus .Radio:not(:checked):not(:disabled) ~ .Radio__mark, .Radio__container:active .Radio:not(:checked):not(:disabled) ~ .Radio__mark, .Radio:checked ~ .Radio__mark {
  background-color: #731c5d;
  border-color: #731c5d;
}

.Radio:checked ~ .Radio__mark:after {
  content: "";
  background-color: #fff;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 6px;
  left: 6px;
}

.Radio:disabled ~ .Radio__mark {
  cursor: not-allowed;
  background-color: #d4d4d8;
  border-color: #6b7280;
}

.Radio__mark {
  border: 2px solid #d4d4d8;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}



.Spinner:before {
  content: "";
  border: 6px solid #731c5d;
  border-color: #731c5d #d4d4d8;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: 2s linear infinite spin;
  display: inline-block;
}

.Spinner__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Spinner__message {
  color: #4b5563;
  margin-top: .5rem;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.TabItem {
  -webkit-appearance: none;
  appearance: none;
  color: #111827;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  align-items: center;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 700;
  transition: color .1s ease-in-out, border .1s ease-in-out;
  display: flex;
}

.TabItem--active {
  color: #731c5d;
  border-bottom: 2px solid #731c5d;
}

.Tabs {
  align-items: center;
  display: flex;
  overflow-x: hidden;
}

.Tabs__container {
  position: relative;
}

.Tabs__navigate {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-color: #fff;
  border: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.Tabs__navigate--left {
  left: 0;
}

.Tabs__navigate--right {
  right: 0;
}

.Toaster {
  z-index: 100;
  flex-direction: column;
  gap: 12px;
  min-width: 350px;
  max-width: 350px;
  display: flex;
  position: fixed;
  top: 72px;
  right: 48px;
}

.Toaster__toast {
  cursor: pointer;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 16px;
  display: flex;
  box-shadow: 0 0 2px rgba(0, 0, 0, .12), 0 8px 16px rgba(0, 0, 0, .14);
}

.Toaster__toast svg {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.Toaster__toast__message {
  flex-grow: 1;
  margin: 0;
  font-size: 14px;
}

.Toaster__toast__message__line {
  margin: 0;
}

.Toaster__toast--success {
  color: #094b30;
  background-color: #edfcf3;
}

.Toaster__toast--error {
  color: #c92a2a;
  background-color: #fff5f5;
}

.Toaster__toast--warning {
  color: #e67700;
  background-color: #fff9db;
}

.Toaster__toast--info {
  color: #1862ab;
  background-color: #e8f7ff;
}

.Toggle__container {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  gap: 12px;
  height: 24px;
  line-height: 24px;
  display: flex;
}

.Toggle__container input {
  display: none;
}

.Toggle__container:hover .Toggle:not(:checked):not(:disabled) ~ .Toggle__control {
  background-color: #4b5563;
}

.Toggle__container:focus .Toggle:not(:checked):not(:disabled) ~ .Toggle__control, .Toggle__container:active .Toggle:not(:checked):not(:disabled) ~ .Toggle__control, .Toggle:checked ~ .Toggle__control {
  background-color: #731c5d;
}

.Toggle:checked ~ .Toggle__control:before {
  content: "";
  left: auto;
  right: 2px;
}

.Toggle:disabled ~ .Toggle__control {
  cursor: not-allowed;
  background-color: #6b7280;
}

.Toggle__control {
  background-color: #d4d4d8;
  border-radius: 1rem;
  width: 44px;
  height: 24px;
  transition: all .1s ease-in-out;
  position: relative;
}

.Toggle__control:before {
  content: "";
  background-color: #fff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.ChangePasswordForm {
  width: 280px;
}

.ChangePasswordForm__field {
  margin-bottom: 1rem;
}

.ChangePasswordForm__field label {
  margin-bottom: .5rem;
  display: block;
}

.ChangePasswordForm__actions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.BasicInfoForm__row {
  gap: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.BasicInfoForm__field {
  flex: 1;
}

.BasicInfoForm__field label {
  margin-bottom: .5rem;
  display: block;
}

.BasicInfoForm__actions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.RevokeTokenForm {
  max-width: 420px;
}

.RevokeTokenForm__field {
  margin-bottom: 1rem;
}

.RevokeTokenForm__field label {
  margin-bottom: .5rem;
  display: block;
}

.RevokeTokenForm__actions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.StyleGuide__example {
  display: flex;
}

.StyleGuide__example__content {
  border: 1px solid #d4d4d8;
  border-radius: 4px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 256px;
  min-height: 256px;
  padding: 1rem;
  display: flex;
}

.AnimatedSubtitle__words {
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  display: flex;
}

.AnimatedSubtitle__word {
  opacity: 0;
  font-size: 20px;
  animation: .2s ease-in-out forwards revealUp;
}

@keyframes revealUp {
  0% {
    opacity: .1;
    transform: translateY(8px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Carousel {
  --duration: 0s;
  --transition-duration: 0s;
  width: 100%;
  position: relative;
}

.Carousel__slide {
  animation: fadeIn var(--transition-duration) ease-in-out, fadeOut var(--transition-duration) ease-in-out var(--duration) forwards;
  border-radius: 24px;
  width: max-content;
  max-width: 100%;
  height: max-content;
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
  box-shadow: 0 8px 10px rgba(0, 0, 0, .1), 0 20px 25px rgba(0, 0, 0, .1);
}

.Carousel__slide--current {
  display: flex;
}

.Carousel__dots {
  gap: 8px;
  display: flex;
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
}

.Carousel__dot {
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, .5);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  transition: background-color .2s ease-in-out;
}

.Carousel__dot--active {
  background-color: #fff;
  border: 1px solid #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.PrivacyPolicyModal {
  border: none;
  border-radius: 8px;
  flex-direction: column;
  gap: 20px;
  width: 712px;
  height: calc(100vh - 128px);
  max-height: calc(100vh - 128px);
  margin: 64px auto;
  padding: 24px 0 24px 24px;
  display: flex;
  top: 0;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .04), 0 20px 25px -5px rgba(0, 0, 0, .1);
}

.PrivacyPolicyModal::backdrop {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(107, 114, 128, .75) !important;
}

.PrivacyPolicyModal .Dialog__close {
  color: #9ca3af;
  top: 0;
  right: 0;
}

.PrivacyPolicyModal__innerScroll {
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  padding: 0 24px 0 0;
  display: flex;
  overflow-y: auto;
}

.PrivacyPolicyModal p {
  margin: 0;
  padding: 0;
}

.PrivacyPolicyModal ul {
  margin: 0;
  padding: 0 0 0 24px;
}

.PrivacyPolicyModal ul > ul {
  list-style-type: disc;
}

.PrivacyPolicyModal ul li:not(:last-child) {
  margin-bottom: .5rem;
}

.PrivacyPolicyModal a {
  color: #329af0;
  text-decoration: none;
}

.PrivacyPolicyModal__title {
  color: #111827;
  font-size: 18px;
  font-weight: 500;
}

.PrivacyPolicyModal__subtitle {
  color: #374151;
  font-size: 14px;
  font-weight: 700;
}

.PrivacyPolicyModal__classic {
  color: #374151;
  font-size: 14px;
}

.PrivacyPolicyModal__bold {
  font-weight: 700;
}

.PrivacyPolicyModal__emphasized {
  font-style: italic;
  font-weight: 700;
}

.PrivacyPolicyModal__italic {
  font-style: italic;
}

.PrivacyPolicyModal__header {
  justify-content: space-between;
  align-items: center;
  padding-right: 56px;
  display: flex;
}

.PrivacyPolicyModal__section {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.PrivacyPolicyModal__orderedList {
  gap: 1rem;
  width: 100%;
  display: flex;
}

.PrivacyPolicyModal__orderedList__number {
  flex: 0 0 2rem;
  width: 2rem;
  font-weight: 700;
}

.PrivacyPolicyModal__orderedList__content {
  flex-direction: column;
  flex: 1 0 0;
  gap: 1rem;
  display: flex;
}

.PrivacyPolicyModal__footer {
  flex-direction: column;
  display: flex;
}

.LandingPage__wave {
  -webkit-clip-path: url("#wave");
  clip-path: url("#wave");
  background-color: #111827;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: -2px 0 0;
  display: flex;
}

.LandingPage__main {
  color: #fff;
  background-color: #111827;
  width: 100%;
}

.LandingPage__main__content {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding-bottom: 248px;
  display: flex;
  position: relative;
}

.LandingPage__main__logo {
  margin-top: 5rem;
}

.LandingPage__main__title {
  text-align: center;
  max-width: 860px;
  margin: 0;
  font-size: 48px;
}

.LandingPage__main__subtitle {
  color: #eb4ace;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 860px;
  height: 48px;
  margin-top: -16px;
  display: flex;
}

.LandingPage__main__button {
  border-radius: 4rem !important;
  padding: 1rem 4rem !important;
  font-size: 20px !important;
}

.LandingPage__main__carousel {
  z-index: 99;
  position: absolute;
  bottom: -185px;
}

.LandingPage__subscribe {
  width: 100%;
}

.LandingPage__subscribe__content {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 20rem;
  margin-bottom: 8rem;
  display: flex;
}

.LandingPage__subscribe__text {
  flex-direction: column;
  flex: 1;
  gap: 4px;
  display: flex;
}

.LandingPage__subscribe__text h3 {
  color: #111827;
  margin: 0;
  font-size: 36px;
}

.LandingPage__subscribe__text p {
  color: #4b5563;
  margin: 0;
  font-size: 20px;
}

.LandingPage__subscribe__form {
  width: 420px;
}

.LandingPage__subscribe__form__button {
  height: 52px;
}

.LandingPage__subscribe__form__inline {
  align-items: flex-start;
  gap: 4px;
  display: flex;
}

.LandingPage__subscribe__form__privacy {
  color: #4b5563;
  margin-top: 4px;
  font-size: 12px;
}

.LandingPage__subscribe__form__privacy a {
  color: #329af0;
  cursor: pointer;
  text-decoration: none;
}

.LandingPage__promo {
  color: #fff;
  background-color: #111827;
  width: 100%;
}

.LandingPage__promo__content {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 650px;
  margin: 0 auto;
  padding: 1rem 0 22rem;
  display: flex;
  position: relative;
}

.LandingPage__promo__title {
  text-align: center;
  margin: 0;
  font-size: 48px;
}

.LandingPage__promo__subtitle {
  color: #9ca3af;
  text-align: center;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.LandingPage__promo__buttons {
  gap: 8px;
  padding-top: 2rem;
  display: flex;
}

.LandingPage__promo__phones {
  z-index: 99;
  position: absolute;
  bottom: -240px;
}

.LandingPage__contact {
  max-width: 624px;
  margin: 14rem auto 8rem;
}

.LandingPage__contact__content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.LandingPage__contact__title {
  color: #111827;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 48px;
}

.LandingPage__contact__subtitle {
  color: #4b5563;
  text-align: center;
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: 500;
}

.LandingPage__contact__form {
  background-color: #f3f4f6;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 2.5rem 4rem;
  display: flex;
}

.LandingPage__contact__form div label {
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 4px !important;
}

.LandingPage__footer {
  color: #fff;
  background-color: #111827;
  width: 100%;
  padding: 4rem 0;
}

.LandingPage__footer__content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.LandingPage__footer p {
  color: #9ca3af;
}

@media only screen and (max-width: 1024px) {
  .LandingPage__subscribe__content {
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 14rem;
  }

  .LandingPage__subscribe__text {
    text-align: center;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .LandingPage__main__title {
    font-size: 30px;
  }

  .LandingPage__subscribe__text {
    gap: 8px;
  }

  .LandingPage__subscribe__text h3 {
    font-size: 30px;
  }

  .LandingPage__subscribe__text p {
    font-size: 16px;
  }

  .LandingPage__promo__title {
    font-size: 30px;
  }

  .LandingPage__promo__subtitle {
    font-size: 16px;
  }

  .LandingPage__contact__title {
    font-size: 30px;
  }

  .LandingPage__contact__subtitle {
    font-size: 16px;
  }

  .LandingPage__contact__form {
    padding: 1.25rem 2rem;
  }
}

@media only screen and (max-width: 480px) {
  .LandingPage__main__content {
    padding-bottom: 180px;
  }

  .LandingPage__main__carousel {
    bottom: -125px;
  }

  .LandingPage__subscribe__form {
    width: 100%;
  }

  .LandingPage__promo__content {
    padding-bottom: 16rem;
  }

  .LandingPage__promo__phones {
    bottom: -120px;
  }

  .LandingPage__promo__buttons {
    flex-direction: column;
    gap: 8px;
  }

  .LandingPage__contact__form {
    padding: 1rem 1.5rem;
  }

  .LandingPage__footer__content {
    flex-direction: column;
    gap: 2rem;
  }
}

/*# sourceMappingURL=index.eb784b62.css.map */
