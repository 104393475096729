@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Card {
  background-color: white;
  border: 1px solid $border;
  min-width: 240px;
  margin: 1rem 0;
  padding: 1rem;
  width: fit-content;

  &.Card--fluid {
    width: 100%;
  }

  &.Card--interactive {
    cursor: pointer;
  }

  &.Card--rounded {
    border-radius: 8px;
  }
}
